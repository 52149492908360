var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"MadalTrasladarProducto",attrs:{"titulo":"Traslado a Cedis","cargando":_vm.cargando},on:{"guardar":_vm.aplicar_devolucion}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row mx-0"},[_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":("required|" + (_vm.producto.cantidad_tipo == 1 ? 'numeric' : 'decimal:2')),"name":"cantidad","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-14 text-general pl-3"},[_vm._v("Cantidad")]),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.cantidad),callback:function ($$v) {_vm.$set(_vm.model, "cantidad", $$v)},expression:"model.cantidad"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required","tag":"div","name":"motivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"pl-3 f-15"},[_vm._v(" Motivo de traslado")]),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccione"},model:{value:(_vm.model.motivo),callback:function ($$v) {_vm.$set(_vm.model, "motivo", $$v)},expression:"model.motivo"}},_vm._l((_vm.motivos),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('ValidationProvider',{staticClass:"col-12",attrs:{"rules":"required","name":"destino","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-14 text-general pl-3"},[_vm._v("Cedis Destino")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","size":"small"},model:{value:(_vm.model.id_cedis_destino),callback:function ($$v) {_vm.$set(_vm.model, "id_cedis_destino", $$v)},expression:"model.id_cedis_destino"}},_vm._l((_vm.select_cedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('div',{staticClass:"col-12 mt-3"},[_c('ValidationProvider',{attrs:{"rules":"required|max:200","name":"observación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"f-14 text-general pl-3"},[_vm._v(" Observación del traslado ")]),_c('el-input',{attrs:{"type":"textarea","rows":4,"maxlength":"200","show-word-limit":""},model:{value:(_vm.model.comentario),callback:function ($$v) {_vm.$set(_vm.model, "comentario", $$v)},expression:"model.comentario"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }