<template>
    <modal ref="MadalTrasladarProducto" titulo="Traslado a Cedis" :cargando="cargando" @guardar="aplicar_devolucion">
        <ValidationObserver ref="validator">
            <div class="row mx-0">
                <ValidationProvider v-slot="{errors}" :rules="`required|${producto.cantidad_tipo == 1 ? 'numeric' : 'decimal:2'}`" name="cantidad" tag="div" class="col-6">
                    <label class="f-14 text-general pl-3">Cantidad</label>
                    <el-input v-model="model.cantidad" size="small" class="w-100" />
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" rules="required" tag="div" class="col-6" name="motivo">
                    <label class="pl-3 f-15"> Motivo de traslado</label>
                    <el-select v-model="model.motivo" placeholder="Seleccione" class="w-100">
                        <el-option
                        v-for="(item, i) in motivos"
                        :key="i"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" rules="required" name="destino" tag="div" class="col-12">
                    <label class="f-14 text-general pl-3">Cedis Destino</label>
                    <el-select v-model="model.id_cedis_destino" filterable size="small" class="w-100">
                        <el-option
                        v-for="item in select_cedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                </ValidationProvider>
                
                <div class="col-12 mt-3">
                    <ValidationProvider v-slot="{errors}" rules="required|max:200" name="observación">
                        <label class="f-14 text-general pl-3"> Observación del traslado </label>
                        <el-input v-model="model.comentario" type="textarea" :rows="4" maxlength="200" show-word-limit />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import ProductosStocks from '~/services/productos/productos_stocks'
import motivosRetiros from '~/services/configurar/admin/motivosRetiros'


export default {
    data(){
        return{
            cargando:false,
            model:{
                cantidad: null,
                comentario: null,
                id_cedis_destino:null,
                motivo: ''
            },
            producto:{},
            motivos: [],
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            cedis:'cedis/cedis'

        }),
        select_cedis(){
            return this.cedis.filter(o=>o.id != 0 && o.id != this.id_cedis)
        },
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        }
    },
    methods: {
        toggle(producto){
            this.limpiarForm()
            this.listarMotivos()
            this.producto = producto
            this.$refs.MadalTrasladarProducto.toggle();
        },
        async listarMotivos(){
            try {
                const {data} = await motivosRetiros.listarTrasladoCedis()
                this.motivos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async aplicar_devolucion(){
            try {
                const valid = await this.$refs.validator.validate()

                if(valid){
                    this.cargando = true
                    this.model.id_cedis_origen = this.id_cedis
                    this.model.id_producto = this.id_producto
                    const {data} = await  ProductosStocks.traslado(this.model)
                    this.cargando = false
                    this.notificacion('Mensaje', 'Traslado realizado', 'success')
                    this.$emit('actualizar')
                    this.$refs.MadalTrasladarProducto.toggle();
                }

            } catch (e){
                this.cargando = false
                this.error_catch(e)
            }
        },
        limpiarForm(){
            this.model={
                cantidad: null,
                comentario: null,
                id_cedis_destino: null,
            }
        }
    }
}
</script>

<style>

</style>
